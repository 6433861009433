define("discourse/plugins/discourse-gitcoin-passport/discourse/templates/connectors/above-main-container/gitcoin-passport-banner", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.showPassportBanner}}
    <div class="row">
      <div class="passport-banner alert alert-info">
        {{{this.bannerText}}}
        <DButton
          @action={{action this.clickCTA}}
          @label="gitcoin_passport_plugin.connect"
          @class="btn-default"
          @icon="plug"
          @isLoading={{this.connectingPassport}}
        />
      </div>
    </div>
  {{/if}}
  
  */
  {
    "id": "pKJlkNWA",
    "block": "[[[41,[30,0,[\"showPassportBanner\"]],[[[1,\"  \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"passport-banner alert alert-info\"],[12],[1,\"\\n      \"],[2,[30,0,[\"bannerText\"]]],[1,\"\\n      \"],[8,[39,1],null,[[\"@action\",\"@label\",\"@class\",\"@icon\",\"@isLoading\"],[[28,[37,2],[[30,0],[30,0,[\"clickCTA\"]]],null],\"gitcoin_passport_plugin.connect\",\"btn-default\",\"plug\",[30,0,[\"connectingPassport\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"d-button\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-gitcoin-passport/discourse/templates/connectors/above-main-container/gitcoin-passport-banner.hbs",
    "isStrictMode": false
  });
});