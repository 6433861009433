define("discourse/plugins/discourse-gitcoin-passport/discourse/connectors/user-preferences-account/gitcoin-passport-preferences-score", ["exports", "@glimmer/component", "@ember/object", "@glimmer/tracking", "discourse/lib/ajax"], function (_exports, _component, _object, _tracking, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class GitcoinPassportPreferencesScore extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "refreshingPassport", [_tracking.tracked], function () {
      return false;
    }))();
    #refreshingPassport = (() => (dt7948.i(this, "refreshingPassport"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "score", [_tracking.tracked], function () {
      return this.args.outletArgs.model.unique_humanity_score;
    }))();
    #score = (() => (dt7948.i(this, "score"), void 0))();
    get passportStatus() {
      const i = this.args.outletArgs.model.gitcoin_passport_status;
      return I18n.t(`gitcoin_passport_plugin.status_${i}`) || "unknown";
    }
    get showUHScore() {
      return this.args.outletArgs.model.gitcoin_passport_status > 2;
    }
    get uniqueHumanityScore() {
      return this.score;
    }
    refreshPassport() {
      this.refreshingPassport = true;
      (0, _ajax.ajax)({
        url: "/gitcoin_passport/refresh_score",
        type: "POST"
      }).then(result => {
        this.score = result.unique_humanity_score;
      }).catch(e => {
        popupAjaxError(e);
      }).finally(() => {
        this.refreshingPassport = false;
      });
    }
    static #_3 = (() => dt7948.n(this.prototype, "refreshPassport", [_object.action]))();
  }
  _exports.default = GitcoinPassportPreferencesScore;
});