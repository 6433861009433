define("discourse/plugins/discourse-gitcoin-passport/discourse/connectors/above-main-container/gitcoin-passport-banner", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/service", "@ember/object", "discourse/models/login-method", "discourse/lib/ajax", "discourse/lib/ajax-error"], function (_exports, _component, _tracking, _service, _object, _loginMethod, _ajax, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class GitcoinPassportBanner extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.inject]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "siteSettings", [_service.inject]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "connectingPassport", [_tracking.tracked], function () {
      return false;
    }))();
    #connectingPassport = (() => (dt7948.i(this, "connectingPassport"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "status", [_tracking.tracked], function () {
      return this.currentUser.gitcoin_passport_status;
    }))();
    #status = (() => (dt7948.i(this, "status"), void 0))();
    get showPassportBanner() {
      if (this.currentUser) {
        return this.status < 3;
      } else {
        return false;
      }
    }
    get bannerText() {
      if (this.currentUser) {
        const i = this.status;
        return I18n.t(`gitcoin_passport_plugin.banner_${i}`) || "unknown";
      }
    }
    clickCTA() {
      if (this.currentUser) {
        const i = this.currentUser.gitcoin_passport_status;
        if (i == 1) {
          // connect to Eth
          const allMethods = (0, _loginMethod.findAll)();
          const siweMethod = allMethods.find(obj => obj.name === 'siwe');
          if (siweMethod) {
            siweMethod.doLogin({
              reconnect: true
            });
          }
        }
        if (i == 2) {
          // connect to Gitcoin Passport
          if (this.connectingPassport) {
            return;
          }
          this.connectingPassport = true;
          (0, _ajax.ajax)({
            url: "/gitcoin_passport/refresh_score",
            type: "POST"
          }).then(result => {
            alert("Your passport score is " + result.unique_humanity_score);
            this.status = 4;
          }).catch(e => {
            (0, _ajaxError.popupAjaxError)(e);
          }).finally(() => {
            this.connectingPassport = false;
          });
        }
      }
    }
    static #_5 = (() => dt7948.n(this.prototype, "clickCTA", [_object.action]))();
  }
  _exports.default = GitcoinPassportBanner;
});