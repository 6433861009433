define("discourse/plugins/discourse-gitcoin-passport/discourse/connectors/user-card-before-badges/gitcoin-passport-usercard-score", ["exports", "@glimmer/component", "@ember/service"], function (_exports, _component, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class GitcoinPassportUsercardScore extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.inject]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    get showUHScore() {
      return this.siteSettings.gitcoin_passport_show_on_usercard && this.args.outletArgs.user.gitcoin_passport_status > 2;
    }
    get uniqueHumanityScore() {
      return this.args.outletArgs.user.unique_humanity_score;
    }
  }
  _exports.default = GitcoinPassportUsercardScore;
});