define("discourse/plugins/discourse-gitcoin-passport/discourse/templates/connectors/user-preferences-account/gitcoin-passport-preferences-score", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
      {{#if this.showUHScore}}
      <label class="control-label">{{i18n "gitcoin_passport_plugin.title"}}</label>
      <div class="controls">
          <span>{{i18n "gitcoin_passport_plugin.unique_humanity_score"}}: {{this.uniqueHumanityScore}}</span>
          <DButton
            @icon="sync"
            @class="btn btn-default"
            @action={{action "refreshPassport"}}
            @label="gitcoin_passport_plugin.refresh"
            @isLoading={{this.refreshingPassport}}
          />
      </div>
    {{/if}}
  */
  {
    "id": "QDGTRw1R",
    "block": "[[[41,[30,0,[\"showUHScore\"]],[[[1,\"    \"],[10,\"label\"],[14,0,\"control-label\"],[12],[1,[28,[35,1],[\"gitcoin_passport_plugin.title\"],null]],[13],[1,\"\\n    \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n        \"],[10,1],[12],[1,[28,[35,1],[\"gitcoin_passport_plugin.unique_humanity_score\"],null]],[1,\": \"],[1,[30,0,[\"uniqueHumanityScore\"]]],[13],[1,\"\\n        \"],[8,[39,2],null,[[\"@icon\",\"@class\",\"@action\",\"@label\",\"@isLoading\"],[\"sync\",\"btn btn-default\",[28,[37,3],[[30,0],\"refreshPassport\"],null],\"gitcoin_passport_plugin.refresh\",[30,0,[\"refreshingPassport\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"i18n\",\"d-button\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-gitcoin-passport/discourse/templates/connectors/user-preferences-account/gitcoin-passport-preferences-score.hbs",
    "isStrictMode": false
  });
});