define("discourse/plugins/discourse-gitcoin-passport/discourse/templates/connectors/admin-user-details/gitcoin-passport-user-admin", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <section class="details">
    <div class="display-row"></div>
    <div class="display-row">
      <div class="field">{{i18n "gitcoin_passport_plugin.title"}}</div>
      <div class="value">{{this.passportStatus}}</div>
      <div class="controls"></div>
    </div>
  
    {{#if this.showUHScore}}
    <div class="display-row">
      <div class="field">{{i18n "gitcoin_passport_plugin.unique_humanity_score"}}</div>
      <div class="value">{{this.uniqueHumanityScore}}</div>
      <div class="controls">
          <DButton
            @icon="sync"
            @class="btn btn-default"
            @action={{action "refreshPassport"}}
            @label="gitcoin_passport_plugin.refresh"
            @isLoading={{this.refreshingPassport}}
      />
      </div>
    </div>
    {{/if}}
  </section>
  
  */
  {
    "id": "gkZcwXVQ",
    "block": "[[[10,\"section\"],[14,0,\"details\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"display-row\"],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"display-row\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"field\"],[12],[1,[28,[35,0],[\"gitcoin_passport_plugin.title\"],null]],[13],[1,\"\\n    \"],[10,0],[14,0,\"value\"],[12],[1,[30,0,[\"passportStatus\"]]],[13],[1,\"\\n    \"],[10,0],[14,0,\"controls\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"showUHScore\"]],[[[1,\"  \"],[10,0],[14,0,\"display-row\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"field\"],[12],[1,[28,[35,0],[\"gitcoin_passport_plugin.unique_humanity_score\"],null]],[13],[1,\"\\n    \"],[10,0],[14,0,\"value\"],[12],[1,[30,0,[\"uniqueHumanityScore\"]]],[13],[1,\"\\n    \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n        \"],[8,[39,2],null,[[\"@icon\",\"@class\",\"@action\",\"@label\",\"@isLoading\"],[\"sync\",\"btn btn-default\",[28,[37,3],[[30,0],\"refreshPassport\"],null],\"gitcoin_passport_plugin.refresh\",[30,0,[\"refreshingPassport\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[],false,[\"i18n\",\"if\",\"d-button\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-gitcoin-passport/discourse/templates/connectors/admin-user-details/gitcoin-passport-user-admin.hbs",
    "isStrictMode": false
  });
});